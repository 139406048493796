export function getAuthHost() {
  let retUrl;
  const currentURL = window.location.href;

  if (
    currentURL.includes('sourcing-capvis-client-dev') ||
    currentURL.includes('gsm-capviz-client-dev') ||
    currentURL.includes('localhost') ||
    currentURL.includes('capvis-regression')
  ) {
    retUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize`;
  } else if (
    currentURL.includes('sourcing-capvis-client-stage') ||
    currentURL.includes('gsm-capviz-client-stage') ||
    currentURL.includes('capacityvisibility-nonprod')
  ) {
    retUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/authorize`;
  } else if (currentURL.includes('gsm-capacityvisibility')) {
    retUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize`;
  }
  return `${retUrl}/`;
}

export function getIssuerHost() {
  let issueUrl;
  const currentURL = window.location.href;

  if (
    currentURL.includes('sourcing-capvis-client-dev') ||
    currentURL.includes('gsm-capviz-client-dev') ||
    currentURL.includes('localhost') ||
    currentURL.includes('capvis-regression')
  ) {
    issueUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7`;
  } else if (
    currentURL.includes('sourcing-capvis-client-stage') ||
    currentURL.includes('gsm-capviz-client-stage') ||
    currentURL.includes('capacityvisibility-nonprod')
  ) {
    issueUrl = `https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7`;
  } else if (currentURL.includes('gsm-capacityvisibility')) {
    issueUrl = `https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7`;
  }
  return `${issueUrl}/`;
}

export const issuerHost = getIssuerHost();

export const serverHost = getAuthHost();

export const oAuthConfig = {
  issuer: `${issuerHost}`,
  clientId: 'nike.niketech.capviz',
  authorizationUrl: `${serverHost}`,
  allowedIdleTime: 1800000 // (milliseconds) Value should be below 1 hour(Current OKTA token expiration time). This is the time by which application will wait for before labelling user as inactive.
};
